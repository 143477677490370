@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
@import "../variables.scss";
body {
  font-family: "Lato", sans-serif;
  margin: 0;

  background-color: $primary;
  color: white;
}

h1 {
  font-weight: 200;
  font-size: 4rem;
}

h4 {
  font-weight: 600;
}

p {
  font-weight: 200;
}

ul {
  list-style: none;
}

.page-contents {
  min-height: 100vh;
  padding: 30px;
}

.contact {
  text-align: center;
  padding: 4%;
  height: 100vh;
}

.download-window {
  height: 100vh;
}

.for-sale {
  padding: 30px;
  text-align: center;
  min-height: 100vh;
}

.for-sale h3,
h4 {
  padding: 30px 0;
}

.for-sale-intro {
  max-width: 80%;
  margin: auto;
}

.for-sale table {
  width: 90%;
  margin: auto;
}

.for-sale tr {
  border-bottom: 1px solid grey;
}

.for-sale td {
  padding: 10px;
}

.film {
  margin: 0;
  padding: 1px;
}

.contents {
  padding: 4%;
  min-height: 100vh;
  text-align: justify;
  animation: fadeIn 400ms ease-in-out;
  max-width: 1400px;
  margin: auto;
}

.cta {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  font-weight: 300;
  padding: 8px 16px;
  border-radius: 8px;
}

.more-cta {
  position: absolute;
  bottom: 30px;
  cursor: pointer;
  transition: 300ms;

  &:hover {
    text-decoration: none;
    color: white;
    background-color: rgba(255, 255, 255, 0.4);
  }
}

.button {
  padding: 9px;
  background-color: lightgray;
  border: 0.5 solid gray;
  border-radius: 10px;
  color: rgb(5, 76, 94);
}

.button:hover {
  text-decoration: none;
}

.choices {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  padding: 10%;
  margin: 2%;
  color: rgb(5, 76, 94);
}

.meta {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  color: rgb(5, 76, 94);
  margin-bottom: 3%;
}

.biog {
  font-weight: 100;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}

.item {
  background-color: whitesmoke;
  padding: 4%;
  margin: 1%;
  box-shadow: 5px 5px lightgray;
  border-radius: 10px;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
}

.gallery img {
  height: 250px;
  margin: 3%;
}
.main-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background-color: black;
  color: white;
}

.music-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
}

.music-nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.photo {
  width: 85%;
}

.photo-container {
  margin: auto;
  display: flex;

  flex-wrap: wrap;
}

/*image overlays*/

.main-box {
  width: 100%;
  position: relative;
  float: left;
  margin: 0 4px;
}
.main-box {
  position: relative;
  z-index: 1;
}
.overlay p {
  text-align: center;
  margin-top: 35%;
}

.overlay img {
  width: 70%;
  margin: auto;
}
.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  opacity: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  background-color: #05050598;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main-box:hover .overlay {
  opacity: 0.9;
}

.hero-title {
  text-align: center;
  p {
    letter-spacing: 0.2rem;
    animation: fadeIn 600ms 0.9s;
    animation-fill-mode: both;
  }
}

.blog-title {
  font-family: "billabongregular";
  font-size: 5rem;
  text-align: center;
}

.social {
  margin-left: 25px;
}

.portrait {
  display: flex;
}

.portrait img {
  height: 250px;
}

.logo {
  width: 35%;
  margin-bottom: 5%;
}

.logos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.webdev {
  background-image: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9));
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  color: $primary;
}

.footer {
  a {
    color: white;
  }
}

.teaching-score {
  margin: 15px;
  background-color: whitesmoke;
  padding: 10px;
  border-radius: 15px;
}

#shopping-cart {
  color: white;
}

#shopping-cart:hover {
  cursor: pointer;
  color: olive;
}

img {
  object-fit: contain;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
